<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        stripe
        multiple
        :sst="true"
        :data="logs"
        @search="search"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>

            <!-- Actions -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('deleteSelected')">
                        <span>Delete</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ itemsPerPage }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>Date Sent</vs-th>
            <vs-th>Broker</vs-th>
            <vs-th>Batch/Client</vs-th>
            <vs-th>Communication</vs-th>
            <vs-th>Sent</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        <vx-tooltip
                            :text="formatDateAndTime(data[index].date_added)"
                        >
                            {{ timeDiffCat(data[index].date_added)[0] }}
                        </vx-tooltip>
                    </vs-td>

                    <vs-td>
                        <span
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'broker',
                                    params: { pk: data[index].broker.email },
                                })
                            "
                            >{{ data[index].broker.company_name }}</span
                        >
                    </vs-td>

                    <vs-td>
                        <div class="flex">
                            <span
                                v-if="
                                    data[index].client &&
                                    data[index].client.user
                                "
                                class="mr-6 link"
                                v-on:click="
                                    $router.push({
                                        name: 'user',
                                        params: { id: data[index].client.user },
                                    })
                                "
                                >{{ data[index].client.name }}</span
                            >
                            <span v-else-if="data[index].client" class="mr-6">{{
                                data[index].client.name
                            }}</span>

                            <span v-if="data[index].batch" class="mr-6"
                                >Batch :
                                {{ data[index].batch.description }}</span
                            >

                            <vs-chip v-if="data[index].client">{{
                                data[index].client.coupon
                            }}</vs-chip>
                        </div>
                    </vs-td>

                    <vs-td>
                        Email - {{ templatesEmail(tr) }} <br />
                        Sms - {{ templatesSms(tr) }}
                    </vs-td>

                    <vs-td>
                        {{ data[index].emails_sent }}<br />{{
                            data[index].sms_sent
                        }}
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'LogsTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage', 'dataSelected', 'limit'],
    computed: {
        logs() {
            if (this.data) return this.data.data
            return null
        },
        selected: {
            set(value) {
                this.$emit('selected', value)
            },
            get() {
                return this.dataSelected
            },
        },
    },
    data() {
        return {
            searchQuery: '',
            status: 'All',
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        to(data) {
            if (data.broker) return data.broker.company_name
            if (data.charity) return data.charity.charity_name
            return ''
        },
        templatesEmail(data) {
            if (data.custom_email_template)
                return data.custom_email_template.description
            else if (data.default_email_template)
                return data.default_email_template.description
            return ''
        },
        templatesSms(data) {
            if (data.custom_sms_template)
                return data.custom_sms_template.description
            else if (data.default_sms_template)
                return data.default_sms_template.description
            return ''
        },
    },
}
</script>
